import React from "react";
import Layout from "../components/layout";
import header from "../images/4bagrHeader.png";
import createLeague from "../images/mockup-create-league.png";
import schedule from "../images/mockup-schedule-3.png";
import scoreboard from "../images/mockup-scoreboard.png";
import findLeagues from "../images/mockup-find-leagues.png";
import promovideo from "../images/PromoUpd.mp4";
import "../components/layout.css";
import Fade from "react-reveal/Fade";

const IndexPage = () => {
  return (
    <Layout title="4BAGR | Next Level Cornhole Leagues" isHome={true}>
      <div className="landing-header">
        <Fade bottom>
          <img
            src={header}
            alt="4Bagr app demo"
            style={{
              display: "block",
              display: "flex",
              maxHeight: 600,
              margin: "auto",
            }}
          />
        </Fade>
      </div>
      <main>
        <section>
          <div className={"info-section-wrap"}>
            <Fade left>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxHeight: 500,
                  maxWidth: 700,
                  padding: 15,
                }}
              >
                <h2>Create a League In Minutes</h2>
                <p>
                  Our League Creation form makes it a breeze to get up and
                  running in a matter of minutes. Choose from several options to
                  shape the layout of your league including league duration, #
                  of courts/teams/matches/players, payouts, handicaps and many
                  more.
                </p>
              </div>
            </Fade>

            <Fade right>
              <div style={{ display: "flex", maxHeight: 500 }}>
                <img
                  src={createLeague}
                  alt="create league app screen"
                  style={{
                    width: "100%",
                    height: "100%",
                    maxHeight: 500,
                    objectFit: "contain",
                  }}
                />
              </div>
            </Fade>
          </div>
        </section>
        <section>
          <div
            className="gradient-bg-wrap light-blue-gradient"
            style={{ overflow: "hidden" }}
          >
            <Fade bottom>
              <h2>Auto Generated and Live Updating Schedule</h2>
              <p style={{ maxWidth: 650, textAlign: "center" }}>
                Quickly and easily view your schedule, standings and team
                rosters in real time. View any week of the schedule at the touch
                of a button to see start times, court assignments and match
                outcomes.
              </p>
            </Fade>
            <Fade bottom>
              <img src={schedule} alt="Schedule mockups" />
            </Fade>
          </div>
        </section>
        <section>
          <div
            style={{
              display: "flex",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              margin: "100px 0 0",
            }}
          >
            <Fade left>
              <div style={{ display: "flex", maxHeight: 700 }}>
                <img
                  src={scoreboard}
                  alt="submit scores and scoreboard"
                  style={{
                    width: "100%",
                    height: "100%",
                    maxHeight: 700,
                    objectFit: "contain",
                  }}
                />
              </div>
            </Fade>

            <Fade right>
              <div
                style={{
                  display: "flex",
                  maxHeight: 700,
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px 25px",
                }}
              >
                <div style={{ maxWidth: 600, alignSelf: "center" }}>
                  <h2>Built in Scoreboard and Score Submission</h2>
                  <p>
                    Players have the ability to track scores through the built
                    in scoreboard as well as submit scores after each game is
                    over, all from their own phone. As soon as a score is
                    submitted, the standings are updated in real time.
                  </p>
                </div>
              </div>
            </Fade>
          </div>
        </section>
        <section>
          <div
            className="gradient-bg-wrap dark-blue-gradient"
            style={{ paddingBottom: 0, overflow: "hidden" }}
          >
            <Fade bottom>
              <h2>Find. Follow. Join.</h2>
              <p style={{ maxWidth: 650, textAlign: "center" }}>
                Our league search feature is nation wide. Simply search by
                league name or location to find, follow, and join leagues near
                you. Your custom league dashboard allows you to easily find and
                access all of your leagues in an organized central location.
              </p>
            </Fade>
            <Fade bottom>
              <img src={findLeagues} alt="Find Leagues mockups" />
            </Fade>
          </div>
        </section>
        <div style={{ maxWidth: 900, margin: "50px auto auto" }}>
          <video controls>
            <source src={promovideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </main>
    </Layout>
  );
};

export default IndexPage;
